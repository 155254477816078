import 'es6-promise/auto'
import Vue from '@/initVue'
import store from '@/store'
import Top from '@/components/pages/Top'

new Vue({
  el: '#app',
  store,
  render: h => h(Top)
})
